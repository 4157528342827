import React from 'react';
import styled, { css } from 'styled-components';
import { Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Organisation } from '@shapeable/gesda-types';
import { ComponentPropsProvider, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayoutProps, SliceLayoutBoundary, SiteHeaderLayout } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { OrganisationViewLayout } from './organisation-view-layout';
const cls = classNames('organisation-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type OrganisationExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Organisation;
};

export const OrganisationExplorerLayoutDefaultProps: Omit<OrganisationExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
  
};

export const OrganisationExplorerLayout: Shapeable.FC<OrganisationExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
    <ExplorerProvider>
    <ComponentPropsProvider value={{
      OrganisationCell: {
        size: 'bannerLarge',
        direction: 'row',
        showSocials: true,
      },
      EntityBubbleImage: {
        showBorder: true,
      },
      OrganisationViewLayout: {
        relatedContentLabel: 'Related Radar Content',
      }
    }}>
    <My.Container>
      <My.Header />
      <SliceLayoutBoundary boundary='none' >
        <My.Layout 
          entity={entity}
          className={cls.name(className)}

          content={
            <OrganisationViewLayout entity={entity} />
          }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </ExplorerProvider>
  )
};

OrganisationExplorerLayout.defaultProps = OrganisationExplorerLayoutDefaultProps;
OrganisationExplorerLayout.cls = cls;