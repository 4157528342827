import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { OrganisationExplorerLayout } from '../../components/entities/organisation-explorer-layout';

export default createGatsbyPageComponent('Organisation', { layout: OrganisationExplorerLayout });

export const organisationQuery = graphql`
  query OrganisationQuery($id: ID!) { 
    platform {
      organisation(id: $id) {
        id slug name path url
        logo { id url url2x type }
        openGraph { title description { id text } image { thumbnails { full { url url2x } } } }
        type { id name slug }
        people {
          id name slug path __typename
          organisation { id name }
          linkedin
          position
          photo { id url url2x type }
        }
        relatedPages {
          id name slug path __typename
          openGraph { title image { url url2x } }
        }
      }
    }
  }
`