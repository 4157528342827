import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Organisation } from '@shapeable/gesda-types';
import { CellLabel, ContentNode, EntityGrid, useLang } from '@shapeable/ui';
import { get } from 'lodash';
const cls = classNames('organisation-view-layout');

// -------- Types -------->

// -------- Props -------->

export type OrganisationViewLayoutProps = Classable & HasChildren & { 
  entity?: Organisation;
};

export const OrganisationViewLayoutDefaultProps: Omit<OrganisationViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const descriptionStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(8)};
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-size: 1em;
    color: ${theme.COLOR('text')};
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const RelatedPagesStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Description: styled(ContentNode)`${descriptionStyles}`,
      Label: styled(CellLabel)`${LabelStyles}`,
      RelatedPages: styled(EntityGrid)`${RelatedPagesStyles}`,
};

export const OrganisationViewLayout: Shapeable.FC<OrganisationViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { openGraph, relatedPages = [] } = entity;

  const hasRelatedPages = !!relatedPages.length;

  const description = get(openGraph, 'description');

  const t = useLang();
  
  return (
    <My.Container className={cls.name(className)}>
      {
        <My.Description entity={description} />
      }
      {
        hasRelatedPages &&
        <>
          <My.Label>{t("Related Radar Content")}</My.Label>
          <My.RelatedPages items={relatedPages} spacing={4} desktopAutoColumns={3} maxColumns={3} desktopMinColumns={2} />
        </>
      }
      {children}
    </My.Container>
  )
};

OrganisationViewLayout.defaultProps = OrganisationViewLayoutDefaultProps;
OrganisationViewLayout.cls = cls;